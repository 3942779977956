import { createAction, props } from '@ngrx/store';

import {
  AccommodationContentAPI,
  AccommodationContentApiDownloadRequest,
  AccommodationContentAPIForm,
} from '../../models';

export const loadRequest = createAction(
  '[Accommodation Content Api] Load Request',
  props<{ accommodationId: number; accommodationName?: string }>(),
);
export const loadSuccess = createAction(
  '[Accommodation Content Api] Load Success',
  props<{ data: AccommodationContentAPI }>(),
);
export const loadFailure = createAction(
  '[Accommodation Content Api] Load Failure',
  props<{ error: any }>(),
);

export const downloadRequest = createAction(
  '[Accommodation Content Api] Download Request',
  props<{ data: AccommodationContentApiDownloadRequest }>(),
);
export const downloadSuccess = createAction(
  '[Accommodation Content Api] Download Success',
);
export const downloadFailure = createAction(
  '[Accommodation Content Api] Download Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Accommodation Content Api] Update Request',
  props<{ accommodationId: number; contentAPI: AccommodationContentAPIForm }>(),
);
export const updateSuccess = createAction(
  '[Accommodation Content Api] Update Success',
);
export const updateFailure = createAction(
  '[Accommodation Content Api] Update Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Accommodation Content Api] Reset State',
);
