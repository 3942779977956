import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AccommodationContentAPI } from '../../models';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectState = createFeatureSelector<State>(
  'accommodationContentApi',
);

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectAccommodationContentApiData = createSelector(
  selectState,
  (state: State): AccommodationContentAPI => state.data,
);
